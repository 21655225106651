import React, { Fragment, useState } from "react";
import { getCarIcon } from "../../caricons/index";
import Comment from "../common/comment";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import StarIcon from "@material-ui/icons/Star";
import PlugIcon from "../../icons/plug";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  row: {
    backgroundColor: (props) =>
      props.grey ? "#eee" : theme.palette.primary.superlight,
    "& td": {
      padding: "4px 12px",
    },
    "& td:nth-child(1)": {
      backgroundColor: (props) => (props.grey ? "#eee" : "#fff"),
      left: 0,
      width: 57,
    },
    "& td:nth-child(2)": {
      backgroundColor: (props) => (props.grey ? "#eee" : "#fff"),
      whiteSpace: (props) => (props.smallScreen ? "normal" : "inherit"),
      left: 57,
      width: 140,
    },
    "& td:nth-child(3)": {
      backgroundColor: (props) => (props.grey ? "#eee" : "#fff"),
      fontWeight: "bold",
      width: 90,
      left: 197,
    },
    "& td:nth-child(4)": {
      backgroundColor: (props) => (props.grey ? "#eee" : "#fff"),
      color: "#006A2E",
      fontWeight: "bold",
      width: 90,
      left: 287,
    },
    "& td:nth-child(n+5)": {
      width: 82,
    },
  },
  ownerCarRow: {
    "& td": {
      borderTop: `2px solid ${theme.palette.primary.main}`,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& td:first-child": {
      borderLeft: `2px solid ${theme.palette.primary.main}`,
    },
    "& td:last-child": {
      borderRight: `2px solid ${theme.palette.primary.main}`,
    },
  },
  sticky: {
    position: "sticky",
  },
  aanbieder: {
    display: "flex",
    flexWrap: "wrap",
    "& >:nth-child(1)": {
      marginRight: 4,
    },
  },
  aanbiederIcons: {
    display: "flex",
    "&>span": {
      marginLeft: 4,
    },
  },
  electric: {
    "& svg": {
      fill: theme.palette.primary.main,
    },
  },
  partiallyElectric: {
    fill: "#bbb",
  },
  info: {
    "& svg": {
      fill: theme.palette.secondary.main,
    },
    "& svg:hover": {
      fill: theme.palette.primary.dark,
      cursor: "pointer",
    },
  },
  carIcon2: {
    stroke: theme.palette.text.primary,
    marginLeft: 6,
    marginTop: -6,
  },
  star: {
    color: "#ddd",
    "& :hover": {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  },
  selectedStar: {
    color: theme.palette.secondary.main,
    "& :hover": {
      cursor: "pointer",
    },
  },
}));

const ResultatenTableBody = ({
  grey,
  moduleIdx,
  labels,
  modules,
  language,
  results,
  resultOwnerCar,
  showAboIncluded,
  totalKm,
  priceView,
  selectedMotiefs,
  selectedAboForComp,
  selectedCarForComp,
  selectable,
  showSelectionColumn,
  selectAbo,
  selectCar,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles({
    smallScreen,
    grey,
    nbrCols: selectedMotiefs.length + 4,
  });

  const [openInfo, setOpenInfo] = useState(false);
  const [commentTitle, setCommentTitle] = useState(null);
  const [commentContent, setCommentContent] = useState(null);

  const handleOpenInfo = (title, content) => () => {
    setCommentTitle(title);
    setCommentContent(content);
    setOpenInfo(true);
  };
  const handleCloseInfo = () => {
    setCommentTitle(null);
    setCommentContent(null);
    setOpenInfo(false);
  };

  return (
    <Fragment>
      <Comment
        open={openInfo}
        title={commentTitle}
        content={commentContent}
        handleClose={handleCloseInfo}
      />
      <TableBody>
        {results.map((abo, rowidx) => (
          <TableRow
            key={rowidx}
            className={
              classes.row +
              (abo.cartype != null ? " " + classes.ownerCarRow : "")
            }
          >
            {showSelectionColumn ? (
              <TableCell key="a" className={classes.sticky}>
                {selectable ? (
                  <StarIcon
                    className={
                      abo.id != null
                        ? selectedAboForComp.includes(abo.id)
                          ? classes.selectedStar
                          : classes.star
                        : selectedCarForComp.includes(abo.carId)
                        ? classes.selectedStar
                        : classes.star
                    }
                    onClick={
                      abo.id != null ? selectAbo(abo.id) : selectCar(abo.carId)
                    }
                  />
                ) : null}
              </TableCell>
            ) : null}
            <TableCell key="b" className={classes.sticky}>
              <span className={classes.aanbieder}>
                <span>
                  {abo.cartype != null ? (
                    <span>
                      {labels[modules[0]].label_eigen_wagen[language]}
                    </span>
                  ) : (
                    abo.naam
                  )}
                </span>
                <span className={classes.aanbiederIcons}>
                  {abo.carelectric ? (
                    <span className={classes.electric}>
                      <PlugIcon />
                    </span>
                  ) : null}
                  {abo.aanbieder &&
                  abo.aanbieder.elektrischaanbod === "volledig" ? (
                    <span className={classes.electric}>
                      <Tooltip
                        title={
                          labels[modules[moduleIdx]]
                            .tooltip_volledig_elektrisch[language]
                        }
                        enterTouchDelay={0}
                      >
                        <span className={classes.info2}>
                          <PlugIcon fontSize="small" />
                        </span>
                      </Tooltip>
                    </span>
                  ) : abo.aanbieder &&
                    abo.aanbieder.elektrischaanbod === "gedeeltelijk" ? (
                    <span className={classes.partiallyElectric}>
                      <Tooltip
                        title={
                          labels[modules[moduleIdx]]
                            .tooltip_gedeeltelijk_elektrisch[language]
                        }
                        enterTouchDelay={0}
                      >
                        <span className={classes.info2}>
                          <PlugIcon fontSize="small" />
                        </span>
                      </Tooltip>
                    </span>
                  ) : null}
                  {abo.aanbieder &&
                  (abo.prijs > 0 || abo.formules[0].prepaid) ? (
                    <span className={classes.electric}>
                      <Tooltip
                        title={
                          labels[modules[moduleIdx]].tooltip_vaste_kosten[
                            language
                          ]
                        }
                        enterTouchDelay={0}
                      >
                        <span className={classes.info2}>
                          <AutorenewOutlinedIcon fontSize="small" />
                        </span>
                      </Tooltip>
                    </span>
                  ) : null}
                  {abo.opmerkingenpubliek ? (
                    <Tooltip
                      title={
                        labels[modules[moduleIdx]].tooltip_meer_info[language]
                      }
                    >
                      <span className={classes.info}>
                        <InfoOutlinedIcon
                          fontSize="small"
                          onClick={handleOpenInfo(
                            abo.naam,
                            language === "nl"
                              ? abo.opmerkingenpubliek
                              : abo.Opmerkingenpubliek_fr
                          )}
                        />
                      </span>
                    </Tooltip>
                  ) : null}
                </span>
              </span>
            </TableCell>
            <TableCell
              key="c"
              className={smallScreen ? "" : " " + classes.sticky}
              align="center"
            >
              {abo.motiefs.some(({ otherAuto }) => otherAuto)
                ? "-"
                : priceView === "m"
                ? `${Math.round(
                    (showAboIncluded
                      ? abo.jaarPrijsMotiefs
                      : abo.jaarPrijsMotiefsZonderAbo) / 12
                  ).toLocaleString("nl-BE")} €`
                : priceView === "y"
                ? `${Math.round(
                    showAboIncluded
                      ? abo.jaarPrijsMotiefs
                      : abo.jaarPrijsMotiefsZonderAbo
                  ).toLocaleString("nl-BE")} €`
                : `${(
                    (showAboIncluded
                      ? abo.jaarPrijsMotiefs
                      : abo.jaarPrijsMotiefsZonderAbo) / totalKm
                  )
                    .toFixed(2)
                    .toLocaleString("nl-BE")} €`}
            </TableCell>
            {resultOwnerCar != null ? (
              <TableCell
                key="d"
                className={smallScreen ? "" : " " + classes.sticky}
                align="center"
              >
                {abo.motiefs.some(({ otherAuto }) => otherAuto)
                  ? "-"
                  : resultOwnerCar.jaarPrijsMotiefs -
                      (showAboIncluded
                        ? abo.jaarPrijsMotiefs
                        : abo.jaarPrijsMotiefsZonderAbo) >
                    0
                  ? priceView === "m"
                    ? `+${Math.round(
                        (resultOwnerCar.jaarPrijsMotiefs -
                          (showAboIncluded
                            ? abo.jaarPrijsMotiefs
                            : abo.jaarPrijsMotiefsZonderAbo)) /
                          12
                      ).toLocaleString("nl-BE")} €`
                    : priceView === "y"
                    ? `+${Math.round(
                        resultOwnerCar.jaarPrijsMotiefs -
                          (showAboIncluded
                            ? abo.jaarPrijsMotiefs
                            : abo.jaarPrijsMotiefsZonderAbo)
                      ).toLocaleString("nl-BE")} €`
                    : `+${(
                        (resultOwnerCar.jaarPrijsMotiefs -
                          (showAboIncluded
                            ? abo.jaarPrijsMotiefs
                            : abo.jaarPrijsMotiefsZonderAbo)) /
                        totalKm
                      )
                        .toFixed(2)
                        .toLocaleString("nl-BE")} €`
                  : "-"}
              </TableCell>
            ) : null}
            {selectedMotiefs.map((sm, idx) => (
              <TableCell
                key={idx}
                align="center"
                style={
                  abo.motiefs[idx].otherAuto
                    ? { color: "#aaa", fontStyle: "italic" }
                    : { color: "#000", fontWeight: 500 }
                }
              >
                {abo.motiefs[idx] != null
                  ? abo.motiefs[idx].otherAuto
                    ? ""
                    : priceView === "m"
                    ? `${Math.round(
                        (showAboIncluded
                          ? abo.motiefs[idx].jaarPrijs
                          : abo.motiefs[idx].jaarPrijsZonderAbo) / 12
                      ).toLocaleString("nl-BE")} €`
                    : priceView === "y"
                    ? `${Math.round(
                        showAboIncluded
                          ? abo.motiefs[idx].jaarPrijs
                          : abo.motiefs[idx].jaarPrijsZonderAbo
                      ).toLocaleString("nl-BE")} €`
                    : `${(
                        (showAboIncluded
                          ? abo.motiefs[idx].jaarPrijs
                          : abo.motiefs[idx].jaarPrijsZonderAbo) /
                        abo.motiefs[idx].jaarKm
                      )
                        .toFixed(2)
                        .toLocaleString("nl-BE")} €`
                  : "-"}
                {/*
                  priceView === 'm' ? `${Math.round((showAboIncluded ? abo.motiefs[idx].jaarPrijs : abo.motiefs[idx].jaarPrijsZonderAbo) /12).toLocaleString('nl-BE')} €`
                      : priceView === 'y' ? `${Math.round((showAboIncluded ? abo.motiefs[idx].jaarPrijs : abo.motiefs[idx].jaarPrijsZonderAbo)).toLocaleString('nl-BE')} €`
                        : `${((showAboIncluded ? abo.motiefs[idx].jaarPrijs : abo.motiefs[idx].jaarPrijsZonderAbo) / abo.motiefs[idx].jaarKm).toFixed(2).toLocaleString('nl-BE')} €`
                */}
                {abo.motiefs[idx] != null ? (
                  abo.motiefs[idx].otherAuto ? (
                    <Tooltip title={abo.motiefs[idx].auto.labels[0][language]}>
                      <span className={classes.carIcon2}>
                        {getCarIcon(abo.motiefs[idx].auto.volgnr, "sm")}
                      </span>
                    </Tooltip>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Fragment>
  );
};

export default ResultatenTableBody;
