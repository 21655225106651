import React from 'react'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ReactMarkdown from 'react-markdown'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import mainicon from '../images/mainicon.png'
import { paths } from '../extras/paths'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${mainicon})`,
    backgroundSize: '35vh',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    marginTop: '7.5vh',
  },
  intro: {
    paddingTop: '35vh',
    width: '100%',
    height: '80vh',
    margin: 'auto',
    textAlign: 'center',
    '& h3': {
      fontSize: '3vh',
      fontWeight: 'bold',
    },
  },
  information: {
    paddingTop: '2vh',
    '& p': {
      display: 'inline',
      fontSize: '1.8vh',
    },
  },
  start: {
    paddingTop: '3vh',
    textAlign: 'center',
    '& a.MuiButton-containedPrimary': {
      color: '#fff',
    },
  },
  persona: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -40,
    '& >img': {
      width: '50%',
      minWidth: 300,
    },
  },
  introSmall: {
    paddingTop: '15vh',
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    '& h3': {
      fontSize: '3vh',
      fontWeight: 'bold',
    },
  },
  button: {
    fontSize: '4vh',
    padding: '2vh 4vw',
    borderRadius: '100px',
    fontWeight: 'bold',
  },
}))

const Start = ({ labels, language }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Container maxWidth='lg' className={classes.container}>
      <div className={classes.intro}>
        <Typography variant='h3'>{labels.titel[language]}</Typography>
        <div className={classes.information}>
          <Typography variant='body1'>
            <ReactMarkdown children={labels.intro[language]} />
          </Typography>
        </div>
        <div className={classes.start}>
          <Button
            disableElevation={true}
            variant='contained'
            size='large'
            color='secondary'
            fullWidth={smallScreen ? true : false}
            className={classes.button}
            component={Link}
            to={paths.motieven[language]}
          >
            {labels.label_start[language]}
          </Button>
        </div>
      </div>
    </Container>
  )
}

export default Start
