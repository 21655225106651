export function flattenObject(obj) {
  function mergeOneLevel(item) {
    if (!item || typeof item !== 'object') {
      return item
    }

    // Flatten 'data' field if present
    if (item.data) {
      return mergeOneLevel(item.data)
    }

    // Merge 'id' and 'attributes' if present, for any object
    if ('id' in item && 'attributes' in item && typeof item.attributes === 'object') {
      return { id: item.id, ...mergeOneLevel(item.attributes) }
    }

    // Recursively handle arrays
    if (Array.isArray(item)) {
      return item.map((element) => mergeOneLevel(element))
    } else {
      // Recursively handle objects
      const result = {}
      for (const key in item) {
        result[key] = mergeOneLevel(item[key])
      }
      return result
    }
  }

  return mergeOneLevel(obj)
}

export function flattenArrayOfObjects(array) {
  return array.map((item) => {
    const flattenedAttributes = flattenObject(item.attributes)
    return { id: item.id, ...flattenedAttributes }
  })
}
