import React from 'react'
import AppBar from '@material-ui/core/AppBar'
// import { Link } from 'react-router-dom'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
// import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined'
// import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import logo from '../../images/logo2.png'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import mainicon from '../../images/mainicon.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& header': {
      boxShadow: 'none'
    },
    marginBottom: props => props.smallScreen ? 60 : 0
  },
  link: {
    marginRight: theme.spacing(2),
    color: '#fff',
    textDecoration: 'none'
  },
  toolbar: {
    maxHeight: 64,
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      color: '#fff',
      borderColor: '#fff'
    }
  },
  logo: {
    height: '13vh',
    marginTop: '8vh'
  },
  languages: {
    marginLeft: 15,
    '& .MuiSelect-select, .MuiSelect-icon': {
      color: '#fff'
    },
    '&.MuiInput-underline:hover::before': {
      // borderBottomColor: '#fff'
      border: 'none'
    },
    '&.MuiInput-underline::after': {
      // borderBottomColor: '#fff'
      border: 'none'
    },
    '&.MuiInput-underline::before': {
      // borderBottomColor: '#fff'
      border: 'none'
    }
  }
}))

const Header = ({ restartApp, labels, language, handleChangeLanguage, withoutLogo }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles({smallScreen})

  const onChangeLanguage = (e) => { handleChangeLanguage(e.target.value) }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar} disableGutters>
            {withoutLogo ? <span>{' '}</span> : <img src={mainicon} className={classes.logo} />}
          {/*
            <Link to="/" className={classes.link}>Start</Link>
            <Link to="/motieven" className={classes.link}>Calculator</Link>
            <Link to="/formules" className={classes.link}>Prijzenoverzicht</Link>
            <Link to="/info" className={classes.link}>Berekeningswijze</Link>
          */}
            <span>
              <Button onClick={restartApp} variant='outlined' size='small' startIcon={<ReplayOutlinedIcon />}>{labels.herstart[language]}</Button>
              <Select
                className={classes.languages}
                value={language}
                onChange={onChangeLanguage}
              >
                <MenuItem value={'nl'} key={'nl'}>NL</MenuItem>
                <MenuItem value={'fr'} key={'fr'}>FR</MenuItem>
              </Select>
            </span>
          </Toolbar>

        </Container>
      </AppBar>
    </div>
  )
}

export default Header
