import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import CountUp from 'react-countup'
import Actions from '../components/common/actions'
import Motief from '../components/motieven/motief'
import MotiefEdit from '../components/motieven/motiefEdit'
import MotiefSelected from '../components/motieven/motiefSelected'
import StepTitle from '../components/common/steptitle'
import Comment from '../components/common/comment'
import OwnerCarsInfo from '../components/ownerCars/ownerCarsInfo'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'
import { paths } from '../extras/paths'

const useStyles = makeStyles((theme) => ({
  container1: {
    marginTop: 10,
    marginBottom: 40,
  },
  container2: {
    marginTop: 15,
    marginBottom: 30,
  },
  totaal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalKm: {
    marginLeft: 6,
    display: 'inline-block',
    textAlign: 'end',
  },
  totalTime: {
    textAlign: 'right',
  },
  info: {
    marginLeft: 6,
    '& svg': {
      fill: theme.palette.secondary.main,
    },
    '& svg:hover': {
      fill: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  info2: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.secondary.main,
    },
    '& p': {
      marginLeft: 6,
      borderBottom: '1px dashed #ccc',
    },
    '& p:hover': {
      fill: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  button: {
    borderRadius: '100px',
    fontWeight: 'bold',
  },
}))

const Motieven = ({
  moduleIdx,
  labels,
  modules,
  language,
  understood,
  handleSetUnderstood,
  motiefs,
  selectedMotiefs,
  handleSelectMotief,
  handleDeSelectMotief,
  handleUpdateMotief,
  autos,
  ownerCars,
}) => {
  const classes = useStyles()
  let navigate = useNavigate()
  let myRef = useRef()

  const [totalKm, setTotalKm] = useState(0)

  const [openInfo, setOpenInfo] = useState(false)
  const [infoTitle, setInfoTitle] = useState(null)
  const [infoContent, setInfoContent] = useState(null)
  const [infoWithUnderstood, setInfoWithUnderstood] = useState(false)

  const [openOwnerCars, setOpenOwnerCars] = useState(false)
  const [openSelected, setOpenSelected] = useState(false)
  const [selectedMotief, setSelectedMotief] = useState(null)

  const handleCloseSelected = () => {
    setSelectedMotief(null)
    setOpenSelected(false)
  }

  const handleSelect = (selectedId) => () => {
    setOpenSelected(true)
    const sm = motiefs.find(({ id }) => id === selectedId)
    setSelectedMotief({ ...sm, naam: sm.labels.find(({ naam }) => naam.endsWith('titel'))[language] || '' })
  }

  const handleDeSelect = (idx) => () => {
    handleDeSelectMotief(idx)
  }

  const handleOpenInfo = (title, content, withUnderStood) => () => {
    setInfoTitle(title)
    setInfoContent(content)
    setInfoWithUnderstood(withUnderStood)
    setOpenInfo(true)
  }
  const handleCloseInfo = (understood) => {
    setInfoTitle(null)
    setInfoContent(null)
    setOpenInfo(false)
    if (infoWithUnderstood) {
      handleSetUnderstood(moduleIdx, understood)
    }
  }

  const handleOpenOwnerCars = () => {
    setOpenOwnerCars(true)
  }
  const handleCloseOwnerCars = () => {
    setOpenOwnerCars(false)
  }

  const handleNext = () => {
    navigate(`/${paths.eigenwagen[language]}`)
  }

  useEffect(() => {
    if (!understood) {
      setInfoTitle(labels[modules[moduleIdx]].titel_popup_intro[language])
      setInfoContent(labels[modules[moduleIdx]].intro[language])
      setInfoWithUnderstood(true)
      setOpenInfo(true)
    }
  }, [moduleIdx, labels, language, modules, understood])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    // console.log('useEffect')
    setTotalKm(
      selectedMotiefs.reduce((acc, sm) => {
        acc += sm.afstand * (sm.frequentietype === 'week' ? 52 : sm.frequentietype === 'maand' ? 12 : 1) * sm.frequentie
        return acc
      }, 0)
    )
    // }, [moduleIdx, labels, modules, language, motiefs, selectedMotiefs, handleSelectMotief, handleDeSelectMotief, handleUpdateMotief, autos, ownerCars])
  }, [moduleIdx, labels, modules, language, motiefs, selectedMotiefs, autos, ownerCars])

  return (
    <Container maxWidth='lg' ref={myRef}>
      {selectedMotief && (
        <MotiefEdit
          language={language}
          labelsModule={labels[modules[moduleIdx]]}
          open={openSelected}
          handleClose={handleCloseSelected}
          handleUpdateMotief={handleUpdateMotief}
          handleSelectMotief={handleSelectMotief}
          idx={null}
          motief={selectedMotief}
          autos={autos}
          autoEditable={true}
        />
      )}
      <Comment
        open={openInfo}
        title={infoTitle}
        content={infoContent}
        handleClose={handleCloseInfo}
        withUnderStood={infoWithUnderstood}
        understood={understood}
        understoodLabel={labels['start'].label_popup_begrepen[language]}
      />
      <OwnerCarsInfo
        language={language}
        labelsModule={labels[modules[1]]}
        open={openOwnerCars}
        ownerCars={ownerCars}
        handleClose={handleCloseOwnerCars}
      />
      <StepTitle labels={labels} modules={modules} language={language} propActiveStep={moduleIdx} />
      {/*      <Typography variant="body1">
        <ReactMarkdown children={labels[modules[moduleIdx]].intro[language]} />
      </Typography>
*/}
      <span
        className={classes.info2}
        onClick={handleOpenInfo(
          labels[modules[moduleIdx]].titel_popup_intro[language],
          labels[modules[moduleIdx]].intro[language],
          true
        )}
      >
        <InfoIcon />
        <Typography variant='body2' component='p'>
          {labels[modules[moduleIdx]].titel_popup_intro[language]}
        </Typography>
      </span>
      <Typography variant='h5'>
        <ReactMarkdown children={labels[modules[moduleIdx]].titel_verplaatsingen[language]} />
      </Typography>

      <Grid container spacing={2} className={classes.container1}>
        {motiefs
          .sort((a, b) => a.volgnr - b.volgnr)
          .map((motief, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
              <Motief
                language={language}
                labelsModule={labels[modules[moduleIdx]]}
                motief={motief}
                handleSelect={handleSelect}
                selected={selectedMotiefs.filter(({ id }) => id === motief.id).length}
              />
            </Grid>
          ))}
      </Grid>
      <div className={classes.totaal}>
        <Typography variant='h5'>
          <ReactMarkdown children={labels[modules[moduleIdx]].titel_selectie[language]} />
        </Typography>
        <Typography variant='h5'>
          <span>{labels[modules[moduleIdx]].km_totaal[language]}</span>
          <span className={classes.totalKm}>
            <CountUp end={totalKm} duration={1.2} preserveValue={true} separator='.' />
            <span>{' ' + labels[modules[moduleIdx]].km_totaal_eenheid[language]}</span>
            {totalKm >= 12000 ? (
              <span className={classes.info}>
                <InfoIcon
                  onClick={handleOpenInfo(
                    labels[modules[moduleIdx]].titel_popup_info[language],
                    labels[modules[moduleIdx]]['info_10000km'][language],
                    false
                  )}
                />
              </span>
            ) : null}
          </span>
        </Typography>
      </div>
      <Typography variant='body2'>
        <ReactMarkdown children={labels[modules[moduleIdx]].info_selectie[language]} />
      </Typography>
      <Grid container spacing={2} className={classes.container2}>
        {selectedMotiefs.map((motief, idx) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
            <MotiefSelected
              language={language}
              labelsModule={labels[modules[moduleIdx]]}
              motief={motief}
              handleDeSelect={handleDeSelect}
              idx={idx}
              handleUpdateMotief={handleUpdateMotief}
              autos={autos}
            />
          </Grid>
        ))}
      </Grid>
      {selectedMotiefs.length > 0 ? (
        <span className={classes.info2} onClick={handleOpenOwnerCars}>
          <InfoIcon />
          <Typography variant='body2' component='span'>
            <ReactMarkdown children={labels[modules[moduleIdx]].knop_info_wagentypes[language]} />
          </Typography>
        </span>
      ) : null}
      <Actions
        actions={
          <Button
            onClick={handleNext}
            disableElevation={true}
            variant='contained'
            size='large'
            color='secondary'
            className={classes.button}
            disabled={selectedMotiefs.length === 0}
          >
            {labels[modules[moduleIdx]].navigatie_volgende[language]}
          </Button>
        }
      />
    </Container>
  )
}

export default Motieven
