import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const pimairBlauw = '#00BAFF'
const heelbleekblauw = '#EBF7FF'
const zwart = '#000'
const lichtgrijs = '#F9F9F9'
const geel = '#FFE922'
const paars = '#4E3AD1'

// A custom theme for this app
let theme1 = createMuiTheme({
  typography: {
    fontFamily: 'Silka, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: pimairBlauw,
      light: heelbleekblauw,
      superlight: lichtgrijs,
      dark: zwart,
    },
    secondary: {
      main: geel
    },
    text: {
      primary: zwart,
      secondary: zwart,
    },
    background: {
      default: '#fff',
    },
    motief: paars,
  },
})

theme1 = responsiveFontSizes(theme1)

export { theme1 }
