import update from 'immutability-helper'
import { calcMotiefsForAbo, calcMotiefsForCar, calcFixPriceForAYear } from './calculator'
import { v4 as uuidv4 } from 'uuid'
import { flattenArrayOfObjects, flattenObject } from './helper'

const isFrenchURL = window.location.href.includes('calculateur')

export const initialState = {
  language: isFrenchURL ? 'fr' : null,
  aanbieders: [],
  abos: [],
  autos: [],
  motiefs: [],
  ownerCars: [],
  algorithm: null,
  labels: null,
  afschrijving: null,
  brandstofs: null,
  versiedatum: new Date(),
  regios: [],
  selectedMotiefs: [],
  selectedOwnerCars: [],
  includeOwnCar: true,
  results: [],
  selectedAboForComp: [],
  selectedCarForComp: [],
  includeCombis: false,
  combis: [],
  tempCombi: null,
  shareOwnCar: { kms: 0, prijs: 0.0 },
  totalTime: 0, // in minuten
  understood: [],
}

const totalKmsInMotifsChanged = (newMotifs) => {
  return newMotifs.reduce((acc, sm) => {
    acc += sm.afstand * (sm.frequentietype === 'week' ? 52 : sm.frequentietype === 'maand' ? 12 : 1) * sm.frequentie
    return acc
  }, 0)
}

const handleCalculateCombi = (combi, state) => {
  const { uuid, selectedAbos, selectedOwnerCars } = combi
  const { selectedMotiefs, autos, brandstofs, afschrijving } = state
  let selectionInComplete =
    selectedAbos.length === 0 ||
    selectedOwnerCars.length === 0 ||
    selectedAbos.some((sa, idx) => sa == null && selectedOwnerCars[idx] == null)

  if (!selectionInComplete) {
    // groepeer motieven per sa
    const toCalculateAbos = selectedAbos.reduce((acc, abo, idx) => {
      if (abo != null) {
        const foundAbo = acc.find(({ m, a }) => a.id === abo.id)
        if (foundAbo) {
          acc.map(({ m, a }) =>
            a.id === abo.id ? { m: m.push({ ...selectedMotiefs[idx], motiefIdx: idx }), a } : { m, a }
          ) // motiefIdx indicates the position of the motief in the combi, necessary for reconciliation later on
        } else {
          acc.push({
            m: [{ ...selectedMotiefs[idx], motiefIdx: idx }],
            a: abo,
          })
        }
      }
      return acc
    }, [])
    
    const groupedResults = toCalculateAbos.map(({ m, a }) => calcMotiefsForAbo(m, a, autos, brandstofs))
    // console.log("groupedResults", groupedResults)

    // groepeer motieven per soc
    const toCalculateOwnerCars = selectedOwnerCars.reduce((acc, soc, idx) => {
      if (soc != null) {
        const foundAbo = acc.find(({ m, c }) => c.id === soc.id)
        if (foundAbo) {
          acc.map(({ m, c }) =>
            c.id === soc.id ? { m: m.push({ ...selectedMotiefs[idx], motiefIdx: idx }), c } : { m, c }
          )
        } else {
          acc.push({
            m: [{ ...selectedMotiefs[idx], motiefIdx: idx }],
            c: soc,
          })
        }
      }
      return acc
    }, [])

    const groupedResultsOwnerCars = toCalculateOwnerCars.map(({ m, c }) =>
      calcMotiefsForCar(m, c, afschrijving, brandstofs)
    )
    // console.log("groupedResultsOwnerCars", groupedResultsOwnerCars)

    const results = selectedMotiefs.map((sm, idx) => {
      let price = null
      let i = 0
      do {
        let m = groupedResults[i] != null ? groupedResults[i].motiefs.find(({ motiefIdx }) => motiefIdx === idx) : null
        if (m != null) {
          price = m.jaarPrijs
        }
        m =
          groupedResultsOwnerCars[i] != null
            ? groupedResultsOwnerCars[i].motiefs.find(({ motiefIdx }) => motiefIdx === idx)
            : null
        if (m != null) {
          price = m.jaarPrijs
        }
        i = i + 1
      } while (price == null)
      return price
    })
    const total =
      groupedResults.reduce((acc, gr) => {
        // console.log('gr.jaarPrijsMotiefs', gr.jaarPrijsMotiefs)
        acc += gr.jaarPrijsMotiefs
        return acc
      }, 0) +
      groupedResultsOwnerCars.reduce((acc, gr) => {
        // console.log('gr.jaarPrijsMotiefs', gr.jaarPrijsMotiefs)
        acc += gr.jaarPrijsMotiefs
        return acc
      }, 0)

    return { uuid, selectedAbos, selectedOwnerCars, results, total }
  } else {
    return combi
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL_FROM_CMS': {
      var { formulas, motiefs, aanbieders, abos, ownerCars, autos, labels, afschrijving, brandstofs, versie, regios } =
        action.payload

      formulas = flattenArrayOfObjects(formulas);
      motiefs = flattenArrayOfObjects(motiefs);
      aanbieders = flattenArrayOfObjects(aanbieders);
      abos = flattenArrayOfObjects(abos);
      ownerCars = flattenArrayOfObjects(ownerCars);
      autos = flattenArrayOfObjects(autos);
      labels = flattenArrayOfObjects(labels);
      brandstofs = flattenArrayOfObjects(brandstofs);
      regios = flattenArrayOfObjects(regios);

      return update(state, {
        motiefs: {
          $set: motiefs.map((motief) => ({
            ...motief,
            auto: autos.find(({ id }) => id === motief.auto.id),
          })),
        },
        aanbieders: { $set: aanbieders },
        abos: {
          $set: abos.map((abo) => ({
            ...abo,
            aanbieder: aanbieders.find(({ id }) => id === abo.aanbieder.id),
            formules: abo.formules
              .filter(({ actief }) => actief)
              .map((formule) => ({
                ...formule,
                autos: formulas.find((f) => f.id === formule.id).autos,
              })),
          })),
        },
        ownerCars: {
          $set: ownerCars.map((oc) => ({
            ...oc,
            auto: autos.find((auto) => auto.id === oc.auto.id),
          })),
        },
        autos: { $set: autos },
        // algorithm: {$set: algorithm.uitleg},
        labels: {
          $set: labels.reduce((acc, l) => {
            if (acc[l.module] == null) {
              acc[l.module] = {}
            }
            acc[l.module][l.naam] = { nl: l.nl, fr: l.fr }
            return acc
          }, {}),
        },
        afschrijving: { $set: flattenObject(afschrijving) },
        brandstofs: {
          $set: {
            prijsperliter: brandstofs.find(({ type }) => type === 'benzine').prijsperliter,
            prijsperkWh: brandstofs.find(({ type }) => type === 'elektrisch').prijsperkWh,
          },
        },
        versiedatum: { $set: new Date(versie.attributes.versiedatum) },
        regios: { $set: regios },
        language: {
          $set: sessionStorage.getItem('language') || isFrenchURL ? 'fr' : 'nl',
        },
        selectedMotiefs: {
          $set: JSON.parse(sessionStorage.getItem('selectedMotiefs') || '[]'),
        },
        // includeOwnCar: { $set: JSON.parse(sessionStorage.getItem('includeOwnCar')) || false },
        includeOwnCar: { $set: true },
        selectedOwnerCars: {
          $set: JSON.parse(sessionStorage.getItem('selectedOwnerCars') || '[]'),
        },
        results: {
          $set: JSON.parse(sessionStorage.getItem('results') || '[]'),
        },
        selectedAboForComp: {
          $set: JSON.parse(sessionStorage.getItem('selectedAboForComp') || '[]'),
        },
        selectedCarForComp: {
          $set: JSON.parse(sessionStorage.getItem('selectedCarForComp') || '[]'),
        },
        includeCombis: {
          $set: JSON.parse(sessionStorage.getItem('includeCombis')) || false,
        },
        combis: { $set: JSON.parse(sessionStorage.getItem('combis') || '[]') },
        understood: {
          $set: JSON.parse(sessionStorage.getItem('understood') || '[]'),
        },
        shareOwnCar: {
          $set: JSON.parse(sessionStorage.getItem('shareOwnCar') || '{ "kms": 0, "prijs": 0.0 }'),
        },
        totalTime: {
          $set: JSON.parse(sessionStorage.getItem('totalTime') || '0'),
        },
      })
      // return state
    }

    case 'RESET_ALL': {
      sessionStorage.setItem('language', 'nl')
      sessionStorage.setItem('selectedMotiefs', [])
      sessionStorage.setItem('includeOwnCar', false)
      sessionStorage.setItem('selectedOwnerCars', [])
      sessionStorage.setItem('results', [])
      sessionStorage.setItem('selectedAboForComp', [])
      sessionStorage.setItem('selectedCarForComp', [])
      sessionStorage.setItem('includeCombis', false)
      sessionStorage.setItem('combis', [])
      sessionStorage.setItem('shareOwnCar', JSON.stringify({ kms: 0, prijs: 0.0 }))
      sessionStorage.setItem('totalTime', 0)
      // sessionStorage.setItem('understood', [])
      return update(state, {
        selectedMotiefs: { $set: [] },
        includeOwnCar: { $set: true },
        selectedOwnerCars: { $set: [] },
        results: { $set: [] },
        selectedAboForComp: { $set: [] },
        selectedCarForComp: { $set: [] },
        includeCombis: { $set: false },
        combis: { $set: [] },
        shareOwnCar: { $set: { kms: 0, prijs: 0.0 } },
        // understood: { $set: [] }
      })
    }

    case 'CHANGE_LANGUAGE': {
      const { language } = action.payload
      sessionStorage.setItem('language', language)
      return update(state, {
        language: { $set: language },
      })
    }

    case 'SET_INCLUDE_OWN_CAR': {
      sessionStorage.setItem('includeOwnCar', !state.includeOwnCar)
      sessionStorage.setItem('selectedOwnerCars', [])
      return update(state, {
        includeOwnCar: { $set: !state.includeOwnCar },
        selectedOwnerCars: { $set: [] },
      })
    }

    case 'DESELECT_MOTIEF': {
      const { idx } = action.payload
      const newValue = state.selectedMotiefs.filter((s, i) => i !== idx)
      const totaalKm = totalKmsInMotifsChanged(newValue)
      const newSelectedOwnerCars = state.selectedOwnerCars.map((selectedOwnerCar) => ({
        ...selectedOwnerCar,
        kmstand: selectedOwnerCar.leeftijd * totaalKm,
      }))
      sessionStorage.setItem('selectedMotiefs', JSON.stringify(newValue))
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newSelectedOwnerCars))
      sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedMotiefs: { $set: newValue },
        selectedOwnerCars: { $set: newSelectedOwnerCars },
        combis: { $set: [] },
        selectedAboForComp: { $set: [] },
        selectedCarForComp: { $set: [] },
      })
    }

    case 'SELECT_MOTIEF': {
      const { motief } = action.payload
      let newValue = state.selectedMotiefs.concat([motief])
      const totaalKm = totalKmsInMotifsChanged(newValue)
      const newSelectedOwnerCars = state.selectedOwnerCars.map((selectedOwnerCar) => ({
        ...selectedOwnerCar,
        kmstand: selectedOwnerCar.leeftijd * totaalKm,
      }))
      sessionStorage.setItem('selectedMotiefs', JSON.stringify(newValue))
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newSelectedOwnerCars))
      sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedMotiefs: { $set: newValue },
        selectedOwnerCars: { $set: newSelectedOwnerCars },
        combis: { $set: [] },
        selectedAboForComp: { $set: [] },
        selectedCarForComp: { $set: [] },
      })
    }

    case 'UPDATE_MOTIEF': {
      const { idx, motief } = action.payload
      let autoWasChanged = false
      const newValue = state.selectedMotiefs.map((s, i) => {
        if (i === idx) {
          if (s.auto.id !== motief.auto.id) {
            autoWasChanged = true
          }
          return motief
        } else {
          return s
        }
      })
      const totaalKm = totalKmsInMotifsChanged(newValue)
      const newSelectedOwnerCars = state.selectedOwnerCars.map((selectedOwnerCar) => ({
        ...selectedOwnerCar,
        kmstand: selectedOwnerCar.leeftijd * totaalKm,
      }))
      sessionStorage.setItem('selectedMotiefs', JSON.stringify(newValue))
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newSelectedOwnerCars))
      // sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedMotiefs: { $set: newValue },
        selectedOwnerCars: { $set: newSelectedOwnerCars },
        combis: { $set: [] },
        shareOwnCar: { $set: { kms: 0, prijs: 0.0 } },
        selectedAboForComp: {
          $set: autoWasChanged ? [] : state.selectedAboForComp,
        },
      })
    }

    // case 'UPDATE_MOTIEF': {
    //   const { idx, prop, value  } = action.payload
    //   const newValue = state.selectedMotiefs.map((s,i) => {
    //     if (i === idx) {
    //       if (prop === 'weekend') {
    //         if (value === true) {
    //           return ({ ...s, [prop]: value, moment: 'dag' })
    //         }
    //       } else if (prop === 'duur') {
    //         if (s.duureenheid === 'dag') {
    //           if (value > 1) {
    //             if (value === 2) {
    //               return ({ ...s, [prop]: value, moment: 'dag' })
    //             } else {
    //               return ({ ...s, [prop]: value, moment: 'dag', weekend: false })
    //             }
    //           }
    //         }
    //       } else if (prop === 'moment') {
    //         return ({ ...s, [prop]: value, weekend: false })
    //       } else if (prop === 'duureenheid') {
    //         return ({ ...s, [prop]: value, duur: 1 })
    //       } else if (prop === 'frequentietype') {
    //         if (s.duureenheid === 'dag') {
    //           return ({ ...s, [prop]: value, frequentie: 1, duur: 1 })
    //         } else {
    //           return ({ ...s, [prop]: value, frequentie: 1 })
    //         }
    //       }
    //       return ({ ...s, [prop]: value })
    //     } else {
    //       return s
    //     }
    //   })
    //   const totaalKm = totalKmsInMotifsChanged(newValue)
    //   const newSelectedOwnerCars = state.selectedOwnerCars.map((selectedOwnerCar) => ({ ...selectedOwnerCar, kmstand: selectedOwnerCar.leeftijd * totaalKm }))
    //   sessionStorage.setItem('selectedMotiefs', JSON.stringify(newValue))
    //   sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newSelectedOwnerCars))
    //   return update(state, {
    //     selectedMotiefs: { $set: newValue },
    //     selectedOwnerCars: { $set: newSelectedOwnerCars }
    //   })
    // }

    case 'SELECT_OWNER_CAR': {
      const { ownerCar } = action.payload
      const newValue = [
        {
          ...ownerCar,
          // set kmstand to leeftijd * total of km's in selected motifs
          // kmstand: ownerCar.leeftijd * state.selectedMotiefs.reduce((acc, sm) => {
          //     acc += sm.afstand * (sm.frequentietype === "week" ? 52 : sm.frequentietype === "maand" ? 12 : 1) * sm.frequentie
          //     return acc
          // }, 0)
          // is now done in the popup, before confirmation
        },
      ]
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newValue))
      sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedOwnerCars: { $set: newValue },
        combis: { $set: [] },
        selectedCarForComp: { $set: [] },
      })
    }

    case 'DESELECT_OWNER_CAR': {
      const { idx } = action.payload
      const newValue = state.selectedOwnerCars.filter((s, i) => i !== idx)
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newValue))
      sessionStorage.setItem('selectedCarForComp', JSON.stringify([]))
      sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedOwnerCars: { $set: newValue },
        selectedCarForComp: { $set: [] },
        combis: { $set: [] },
      })
    }

    case 'UPDATE_OWNER_CAR': {
      const { ownerCar } = action.payload
      const newValue = [
        {
          ...ownerCar,
        },
      ]
      sessionStorage.setItem('selectedOwnerCars', JSON.stringify(newValue))
      sessionStorage.setItem('combis', JSON.stringify([]))
      return update(state, {
        selectedOwnerCars: { $set: newValue },
        combis: { $set: [] },
      })
    }

    case 'SELECT_ABO_FOR_COMP': {
      const { selectedId } = action.payload
      const { selectedAboForComp } = state
      const newValue = selectedAboForComp.includes(selectedId)
        ? selectedAboForComp.filter((id) => id !== selectedId)
        : selectedAboForComp.concat([selectedId])
      sessionStorage.setItem('selectedAboForComp', JSON.stringify(newValue))
      return update(state, {
        selectedAboForComp: { $set: newValue },
      })
    }

    case 'SELECT_CAR_FOR_COMP': {
      const { selectedCarId } = action.payload
      const { selectedCarForComp } = state
      const newValue = selectedCarForComp.includes(selectedCarId)
        ? selectedCarForComp.filter((carId) => carId !== selectedCarId)
        : selectedCarForComp.concat([selectedCarId])
      sessionStorage.setItem('selectedCarForComp', JSON.stringify(newValue))
      return update(state, {
        selectedCarForComp: { $set: newValue },
      })
    }

    case 'SELECT_ALL_ABO_FOR_COMP': {
      const { selectedIds } = action.payload
      sessionStorage.setItem('selectedAboForComp', JSON.stringify(selectedIds))
      return update(state, {
        selectedAboForComp: { $set: selectedIds },
      })
    }

    case 'DESELECT_ALL_FOR_COMP': {
      sessionStorage.setItem('selectedAboForComp', JSON.stringify([]))
      sessionStorage.setItem('selectedCarForComp', JSON.stringify([]))
      return update(state, {
        selectedAboForComp: { $set: [] },
        selectedCarForComp: { $set: [] },
      })
    }

    case 'CALCULATE': {
      // setSelectedAboForComp([])
      // sessionStorage.setItem('selectedAboForComp', JSON.stringify([]))
      // setSelectedCarForComp([])
      // sessionStorage.setItem('selectedCarForComp', JSON.stringify([]))

      const daResult = state.abos.map((abo) => ({
        ...abo,
        ...calcMotiefsForAbo(state.selectedMotiefs, abo, state.autos, state.brandstofs),
      }))
      if (state.includeOwnCar) {
        state.selectedOwnerCars.forEach((ownerCar) => {
          daResult.push(calcMotiefsForCar(state.selectedMotiefs, ownerCar, state.afschrijving, state.brandstofs))
        })
      }

      // ZONDER ABO

      // sorteren op geschikt en dan op prijs
      let sortedResults = daResult.sort((a, b) => {
        if (a.motiefs.some(({ otherAuto }) => otherAuto)) {
          if (b.motiefs.some(({ otherAuto }) => otherAuto)) {
            return a.jaarPrijsMotiefsZonderAbo - b.jaarPrijsMotiefsZonderAbo
          } else {
            return 1
          }
        } else if (b.motiefs.some(({ otherAuto }) => otherAuto)) {
          return -1
        } else {
          return a.jaarPrijsMotiefsZonderAbo - b.jaarPrijsMotiefsZonderAbo
        }
      })

      // set cheapest-flag
      let found = []
      sortedResults = sortedResults.map((r) => {
        if (r.aanbieder == null) {
          return { ...r, cheapestZonderAbo: true }
        } else if (found.indexOf(r.aanbieder.id) === -1) {
          found.push(r.aanbieder.id)
          return { ...r, cheapestZonderAbo: true }
        } else {
          return { ...r, cheapestZonderAbo: false }
        }
      })

      // MET ABO

      // console.log("daResult", daResult);
      // sorteren op geschikt en dan op prijs
      sortedResults = sortedResults.sort((a, b) => {
        if (a.motiefs.some(({ otherAuto }) => otherAuto)) {
          if (b.motiefs.some(({ otherAuto }) => otherAuto)) {
            return a.jaarPrijsMotiefs - b.jaarPrijsMotiefs
          } else {
            return 1
          }
        } else if (b.motiefs.some(({ otherAuto }) => otherAuto)) {
          return -1
        } else {
          return a.jaarPrijsMotiefs - b.jaarPrijsMotiefs
        }
      })
      // console.log('sortedResults', sortedResults);

      // set cheapest-flag
      found = []
      sortedResults = sortedResults.map((r) => {
        if (r.aanbieder == null) {
          return { ...r, cheapest: true }
        } else if (found.indexOf(r.aanbieder.id) === -1) {
          found.push(r.aanbieder.id)
          return { ...r, cheapest: true }
        } else {
          return { ...r, cheapest: false }
        }
      })

      let newCombis = state.combis.map((c) => {
        return handleCalculateCombi(c, state)
      })

      const totalTime = state.selectedMotiefs.reduce((acc, sm) => {
        acc +=
          sm.duur *
          (sm.duureenheid === 'dag' ? 1440 : sm.duureenheid === 'uur' ? 60 : 1) *
          (sm.frequentietype === 'week' ? 52 : sm.frequentietype === 'maand' ? 12 : 1) *
          sm.frequentie
        return acc
      }, 0)

      sessionStorage.setItem('combis', JSON.stringify(newCombis))
      sessionStorage.setItem('results', JSON.stringify(sortedResults))
      sessionStorage.setItem('totalTime', JSON.stringify(totalTime))
      return update(state, {
        results: { $set: sortedResults },
        combis: { $set: newCombis },
        totalTime: { $set: totalTime },
      })
    }

    case 'SET_INCLUDE_COMBIS': {
      sessionStorage.setItem('includeCombis', !state.includeCombis)
      sessionStorage.setItem('combis', [])
      return update(state, {
        includeCombis: { $set: !state.includeCombis },
        combis: { $set: [] },
      })
    }

    // case 'ADD_COMBI': {
    //   const newCombis = state.combis.concat([{
    //     uuid: uuidv4(),
    //     selectedAbos: new Array(state.selectedMotiefs.length).fill(null),
    //     selectedOwnerCars: new Array(state.selectedMotiefs.length).fill(null),
    //     total: null,
    //     results: []
    //   }])
    //   sessionStorage.setItem('combis', JSON.stringify(newCombis))
    //   return update(state, {
    //     combis: { $set: newCombis }
    //   })
    // }
    case 'SAVE_COMBI': {
      let newCombis = []
      if (state.tempCombi.uuid == null) {
        newCombis = state.combis.concat([
          {
            ...state.tempCombi,
            uuid: uuidv4(),
          },
        ])
      } else {
        newCombis = state.combis.map((c) => {
          if (c.uuid === state.tempCombi.uuid) {
            return state.tempCombi
          } else {
            return c
          }
        })
      }
      sessionStorage.setItem('combis', JSON.stringify(newCombis))
      return update(state, {
        combis: { $set: newCombis },
      })
    }

    case 'DELETE_COMBI': {
      const { uuid } = action.payload
      const newCombis = state.combis.filter((c) => c.uuid !== uuid)
      sessionStorage.setItem('combis', JSON.stringify(newCombis))
      return update(state, {
        combis: { $set: newCombis },
      })
    }

    case 'UPDATE_COMBI': {
      const { uuid, idx, value, isAbo } = action.payload
      let newCombis = state.combis.map((c) => {
        if (c.uuid === uuid) {
          return handleCalculateCombi(
            {
              total: null,
              results: [],
              uuid: c.uuid,
              selectedAbos: isAbo
                ? c.selectedAbos.map((sa, i) => (i === idx ? state.abos.find(({ id }) => id === value) : sa))
                : c.selectedAbos.map((sa, i) => (i === idx ? null : sa)),
              selectedOwnerCars: isAbo
                ? c.selectedOwnerCars.map((oc, i) => (i === idx ? null : oc))
                : c.selectedOwnerCars.map((oc, i) =>
                    i === idx ? state.selectedOwnerCars.find(({ id }) => id === value) : oc
                  ),
            },
            state
          )
        } else {
          return c
        }
      })
      sessionStorage.setItem('combis', JSON.stringify(newCombis))
      return update(state, {
        combis: { $set: newCombis },
      })
    }

    case 'SET_TEMPORARY_COMBI': {
      if (action.payload.uuid == null) {
        return update(state, {
          tempCombi: {
            $set: {
              uuid: null,
              selectedAbos: new Array(state.selectedMotiefs.length).fill(null),
              selectedOwnerCars: new Array(state.selectedMotiefs.length).fill(null),
              total: null,
              results: [],
            },
          },
        })
      } else {
        return update(state, {
          tempCombi: {
            $set: state.combis.find((c) => c.uuid === action.payload.uuid),
          },
        })
      }
    }

    case 'UPDATE_TEMPORARY_COMBI': {
      const { idx, value, isAbo } = action.payload
      const c = state.tempCombi
      return update(state, {
        tempCombi: {
          $set: handleCalculateCombi(
            {
              uuid: c.uuid,
              selectedAbos: isAbo
                ? c.selectedAbos.map((sa, i) => (i === idx ? state.abos.find(({ id }) => id === value) : sa))
                : c.selectedAbos.map((sa, i) => (i === idx ? null : sa)),
              selectedOwnerCars: isAbo
                ? c.selectedOwnerCars.map((oc, i) => (i === idx ? null : oc))
                : c.selectedOwnerCars.map((oc, i) =>
                    i === idx ? state.selectedOwnerCars.find(({ id }) => id === value) : oc
                  ),
              total: null,
              results: [],
            },
            state
          ),
        },
      })
    }

    case 'UPDATE_SHARE_OWN_CAR': {
      // value = shared kms
      const { value } = action.payload
      const jaarKmMotiefs =
        state.results[0].jaarKmMotiefs != null ? state.results[0].jaarKmMotiefs : state.results[1].jaarKmMotiefs
      const newPrijs = (
        calcFixPriceForAYear(state.selectedOwnerCars[0], state.afschrijving) *
        (value / (jaarKmMotiefs + value))
      ).toFixed(2)
      sessionStorage.setItem('shareOwnCar', JSON.stringify({ kms: value, prijs: newPrijs }))
      return update(state, {
        shareOwnCar: {
          $set: {
            kms: value,
            prijs: newPrijs,
          },
        },
      })
    }

    case 'SET_UNDERSTOOD': {
      const { idx, understood } = action.payload
      let newValue = state.understood
      if (understood) {
        if (!newValue.includes(idx)) {
          newValue = newValue.concat([idx])
        }
      } else {
        newValue = newValue.filter((u) => u !== idx)
      }
      sessionStorage.setItem('understood', JSON.stringify(newValue))
      return update(state, {
        understood: { $set: newValue },
      })
    }

    default: {
      return state
    }
  }
}
