import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Checkbox, FormControlLabel, Button } from '@material-ui/core'
import ReactMarkdown from 'react-markdown'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { strapiUrl } from '../../service'

const useStyles = makeStyles((theme) => ({
  modaltitle: {
    paddingBottom: 0,
    '& div:nth-child(1)': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 10,
      borderBottom: '1px dashed',
    },
    '& svg': {
      fill: theme.palette.secondary.main,
      marginTop: -6,
      marginRight: -6,
    },
    '& svg:hover': {
      cursor: 'pointer',
    },
  },
  modalButtonContainer: {
    marginTop: 30,
    // padding: 20,
    // display: 'flex',
    // justifyContent: 'flex-end',
    '&>button.MuiButton-containedPrimary': {
      marginTop: 10,
      marginBottom: 20,
      color: '#fff',
    },
  },
  content: {
    '& a': {
      color: theme.palette.primary.main,
    },
    '& img': {
      height: 20,
      marginLeft: 5,
      marginRight: 5,
    },
  },
  button: {
    borderRadius: '100px',
    fontWeight: 'bold',
  },
}))

const CommentWithUnderstood = ({ open, title, content, withUnderStood, understood, understoodLabel, handleClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const [thisUnderstood, setThisUnderstood] = useState(false)

  const handleUnderstoodClicked = () => {
    setThisUnderstood(!thisUnderstood)
  }

  const onCloseClicked = () => {
    handleClose(thisUnderstood)
  }

  useEffect(() => {
    if (withUnderStood) {
      setThisUnderstood(understood)
    }
  }, [withUnderStood, understood])

  if (open) {
    return (
      <Dialog fullScreen={fullScreen} fullWidth maxWidth='sm' onClose={onCloseClicked} open={open}>
        <DialogTitle className={classes.modaltitle}>
          <div>
            <span>{title}</span>
            <DeleteIcon onClick={onCloseClicked} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <ReactMarkdown children={content.split('/uploads/').join(`${strapiUrl}/uploads/`)} linkTarget='_blank' />
          {withUnderStood && (
            <div className={classes.modalButtonContainer}>
              <div>
                <FormControlLabel
                  control={<Checkbox onClick={handleUnderstoodClicked} checked={thisUnderstood} color='secondary' />}
                  label={understoodLabel}
                />
              </div>
              <Button
                disableElevation={true}
                size='medium'
                variant='contained'
                color='secondary'
                className={classes.button}
                onClick={onCloseClicked}
              >
                ok
              </Button>
            </div>
          )}
        </DialogContent>
        {!withUnderStood && (
          <DialogActions className={classes.modalButtonContainer}>
            <Button
              disableElevation={true}
              size='medium'
              variant='contained'
              color='secondary'
              className={classes.button}
              onClick={onCloseClicked}
            >
              ok
            </Button>
          </DialogActions>
        )}
      </Dialog>
    )
  } else {
    return null
  }
}

export default CommentWithUnderstood
