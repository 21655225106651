import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import Comment from '../components/common/comment'
import Grid from '@material-ui/core/Grid'
import StepTitle from '../components/common/steptitle'
import OwnerCar from '../components/ownerCars/ownerCar'
import OwnerCarEdit from '../components/ownerCars/ownerCarEdit'
import OwnerCarSelected from '../components/ownerCars/ownerCarSelected'
import Actions from '../components/common/actions'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
// import { Checkbox, FormControlLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'
import { paths } from '../extras/paths'

const useStyles = makeStyles((theme) => ({
  container1: {
    marginTop: 10,
    marginBottom: 40,
  },
  container2: {
    marginTop: 15,
    marginBottom: 30,
  },
  information: {
    padding: '20px 0',
    // color: '#333'
  },
  info2: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: theme.palette.secondary.main
    },
    '& p': {
      marginLeft: 6,
      borderBottom: '1px dashed #ccc'
    },
    '& p:hover': {
      fill: theme.palette.primary.dark,
      cursor: 'pointer'
    }
  },
  button: {
    borderRadius: '100px',
    color: '#000',fontWeight: 'bold',
  }
}))

const EigenWagen = ({ moduleIdx, labels, modules, language, understood, handleCalculate, includeOwnCar, handleIncludeOwnCar, ownerCars, selectedOwnerCars, selectedMotiefs, handleSelectOwnerCar, handleDeSelectOwnerCar, handleUpdateOwnerCar, handleSetUnderstood }) => {
  const classes = useStyles()
  let navigate = useNavigate()

  const [openSelected, setOpenSelected] = useState(false)
  const [selectedOwnerCar, setSelectedOwnerCar] = useState(null)

  const [openInfo, setOpenInfo] = useState(false)
  const [infoTitle, setInfoTitle] = useState(null)
  const [infoContent, setInfoContent] = useState(null)
  const [infoWithUnderstood, setInfoWithUnderstood] = useState(false)

  const handleCloseSelected = () => {
    setSelectedOwnerCar(null)
    setOpenSelected(false)
  }

  const handleSelect = (selectedId) => () => {
    // handleSelectOwnerCar(selectedId)
    setOpenSelected(true)
    const oc = ownerCars.find(({id}) => id === selectedId)
    setSelectedOwnerCar({
      ...oc,
      // set kmstand to leeftijd * total of km's in selected motifs
      kmstand: oc.leeftijd * selectedMotiefs.reduce((acc, sm) => {
        acc += sm.afstand * (sm.frequentietype === "week" ? 52 : sm.frequentietype === "maand" ? 12 : 1) * sm.frequentie
        return acc
      }, 0)
    })
  }

  const handleDeSelect = (idx) => () => {
    handleDeSelectOwnerCar(idx)
  }

  const handlePrevious = () => {
    navigate(`/${paths.motieven[language]}`)
  }

  const handleNext = () => {
    handleCalculate()
    navigate("/formulier")
  }

  const handleOpenInfo = (title, content, withUnderStood) => () => {
    setInfoTitle(title)
    setInfoContent(content)
    setInfoWithUnderstood(withUnderStood)
    setOpenInfo(true)
  }
  const handleCloseInfo = (understood) => {
    setInfoTitle(null)
    setInfoContent(null)
    setOpenInfo(false)
    if (infoWithUnderstood) {
      handleSetUnderstood(moduleIdx,understood)
    }
  }

  useEffect(() => {
    if (!understood) {
      setInfoTitle(labels[modules[moduleIdx]].titel_popup_intro[language])
      setInfoContent(labels[modules[moduleIdx]].intro[language])
      setInfoWithUnderstood(true)
      setOpenInfo(true)
    }
  }, [moduleIdx, labels, language, modules, understood])

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <Container maxWidth="lg">
      <Comment
        open={openInfo}
        title={infoTitle}
        content={infoContent}
        handleClose={handleCloseInfo}
        withUnderStood={infoWithUnderstood}
        understood={understood}
        understoodLabel={labels['start'].label_popup_begrepen[language]}
      />
      {selectedOwnerCar &&
        <OwnerCarEdit
          language={language}
          labelsModule={labels[modules[moduleIdx]]}
          open={openSelected}
          adding={true}
          handleClose={handleCloseSelected}
          handleUpdateOwnerCar={handleSelectOwnerCar}
          ownerCar={selectedOwnerCar}
        />
      }    
      <StepTitle labels={labels} modules={modules} language={language} propActiveStep={moduleIdx} />

      {/* 
      <div className={classes.information}>
        <Typography variant="body1">
          <ReactMarkdown children={labels[modules[moduleIdx]].intro[language]} />
        </Typography>
      </div>
      */}

      <span className={classes.info2} onClick={handleOpenInfo(labels[modules[moduleIdx]].titel_popup_intro[language], labels[modules[moduleIdx]].intro[language], true)}>
        <InfoIcon />
        <Typography variant='body2' component='p'>
          {labels[modules[moduleIdx]].titel_popup_intro[language]}
        </Typography>
      </span> 

      {/* <div>
        <FormControlLabel
          control={
            <Checkbox onClick={handleIncludeOwnCar} checked={includeOwnCar} color="primary"/>
          }
          label={labels[modules[moduleIdx]].tekst_1[language]}
        />
      </div>
      */}

      {includeOwnCar ? (
        <div>
          {/*
            <div className={classes.information}><Typography variant="body1">{labels[modules[moduleIdx]].tekst_2[language]}</Typography></div>
           */}
          <Grid container spacing={2} className={classes.container1}>
            {ownerCars.map((ownerCar, idx) =>
              <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                <OwnerCar
                  language={language}
                  labelsModule={labels[modules[moduleIdx]]}                
                  ownerCar={ownerCar}
                  handleSelect={handleSelect}
                  disabled={selectedOwnerCars.find((soc) => soc.id === ownerCar.id) != null}
                />
              </Grid>
            )}
          </Grid>

          <Typography variant="h5" component='p'><ReactMarkdown children={labels[modules[moduleIdx]].titel_selectie[language]} /></Typography>
          <Grid container spacing={2} className={classes.container2}>
            {selectedOwnerCars.map((ownerCar, idx) => 
              <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                <OwnerCarSelected
                  language={language}
                  labelsModule={labels[modules[moduleIdx]]}                  
                  ownerCar={ownerCar}
                  handleDeSelect={handleDeSelect}
                  idx={idx}
                  handleUpdateOwnerCar={handleUpdateOwnerCar}
                />
              </Grid>
            )}
          </Grid>

        </div>
      ) : null }

      <Actions
        actions={[
          <Button disableElevation={true} key='prev' onClick={handlePrevious} variant="outlined" size="large" color="secondary" className={classes.button}>{labels[modules[moduleIdx]].navigatie_terug[language]}</Button>,
          <Button disableElevation={true} key='next' onClick={handleNext} variant="contained" size="large" color="secondary" className={classes.button} disabled={selectedOwnerCars.length === 0}>{labels[modules[moduleIdx]].navigatie_volgende[language]}</Button>
        ]}
      />
    </Container>
  )
}

export default EigenWagen
