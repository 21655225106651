import React, { useEffect, useState, useRef } from 'react'
import { getCarIcon } from '../../caricons/index'
import MotiefEdit from './motiefEdit'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/HighlightOff'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ScheduleIcon from '@material-ui/icons/Schedule'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import WbSunnyOutlinedIcon from '@material-ui/icons/WbSunnyOutlined'
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined'
import BeachAccessOutlinedIcon from '@material-ui/icons/BeachAccessOutlined'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  motif: {
    backgroundColor: '#fff',
    padding: 20,
    height: 300,
  },
  title: {
    paddingBottom: 10,
    borderBottom: '1px dashed',
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    '& h6': {
      // fontWeight: 'bold'
    },
    '& svg': {
      fill: theme.palette.primary.main,
      marginTop: -6,
      marginRight: -6,
    },
    '& svg:hover': {
      cursor: 'pointer',
    },
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    height: 32,
    padding: '6px 0',
    '& svg': {
      marginRight: 12,
      fill: theme.palette.motief,
    },
    '& >:nth-child(2)': {
      marginRight: 10,
    },
    '& >:nth-child(3)': {
      fontWeight: 'bold',
    },
  },
  carIcon: {
    stroke: theme.palette.motief,
  },
  editButton: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      borderRadius: '100px',
      fontWeight: 'bold',
      color: '#000',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const MotiefSelected = ({ language, labelsModule, motief, handleDeSelect, idx, handleUpdateMotief, autos }) => {
  const classes = useStyles({ naam: motief.naam })
  const { naam, frequentie, frequentietype, afstand, duur, duureenheid, moment, auto, weekend } = motief
  const [open, setOpen] = useState(false)
  let myRef = useRef()

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: myRef.current.offsetTop - 300 })
  }, [])

  return (
    <div ref={myRef}>
      <MotiefEdit
        language={language}
        labelsModule={labelsModule}
        open={open}
        handleClose={handleClose}
        handleUpdateMotief={handleUpdateMotief}
        idx={idx}
        motief={motief}
        autos={autos}
        autoEditable={true}
      />
      <Paper className={classes.motif}>
        <div className={classes.title}>
          <Typography variant='h6'>
            {/* {
              id == null ? ( */}
            {naam}
            {/*   ) : (
                labels.find(({ naam }) => naam.endsWith('titel'))[language]
              )
            } */}
          </Typography>
          <DeleteIcon onClick={handleDeSelect(idx)} />
        </div>
        <div className={classes.list}>
          <span className={classes.item}>
            <EventNoteIcon />
            <span>{labelsModule.label_frequentie[language]}:</span>
            <span>
              {`${frequentie} / ${
                frequentietype === 'jaar'
                  ? labelsModule.label_jaar[language]
                  : frequentietype === 'maand'
                  ? labelsModule.label_maand[language]
                  : labelsModule.label_week[language]
              }
              `}
            </span>
          </span>
          <span className={classes.item}>
            <ScheduleIcon />
            <span>{labelsModule.label_duur[language]}:</span>
            <span>
              {`${duur} ${
                duureenheid === 'minuten'
                  ? labelsModule.label_minuut_kort[language]
                  : duureenheid === 'uur'
                  ? labelsModule.label_uur_kort[language]
                  : labelsModule.label_dag_kort[language]
              } / ${labelsModule.label_rit[language]}
              `}
            </span>
          </span>
          {(duureenheid === 'uur' && duur <= 12) || duureenheid === 'minuten' ? (
            moment === 'dag' ? (
              <span className={classes.item}>
                <WbSunnyOutlinedIcon />
                <span>{labelsModule.label_moment[language]}:</span>
                <span>{labelsModule.label_overdag[language]}</span>
              </span>
            ) : (
              <span className={classes.item}>
                <Brightness2OutlinedIcon />
                <span>{labelsModule.label_moment[language]}:</span>
                <span>{labelsModule.label_snachts[language]}</span>
              </span>
            )
          ) : (duureenheid === 'uur' && duur <= 48) || (duureenheid === 'dag' && duur === 2) ? (
            weekend ? (
              <span className={classes.item}>
                <BeachAccessOutlinedIcon />
                <span>{labelsModule.label_moment[language]}:</span>
                <span>{labelsModule.label_weekend[language]}</span>
              </span>
            ) : (
              <span className={classes.item}></span>
            )
          ) : (
            <span className={classes.item}></span>
          )}
          <span className={classes.item}>
            <SyncAltIcon />
            <span>{labelsModule.label_afstand[language]}:</span>
            <span>{`${afstand} ${labelsModule.label_km_ht[language]}`}</span>
          </span>
          <span className={classes.item + ' ' + classes.carIcon}>
            {getCarIcon(auto.volgnr, 'sm')}
            <span>{labelsModule.label_wagen[language]}:</span>
            <span>{auto.labels[0][language]}</span>
          </span>
        </div>
        <div className={classes.editButton}>
          <Button variant='contained' color='secondary' className={classes.button} onClick={handleOpen}>
            {labelsModule.knop_aanpassen[language]}
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default MotiefSelected
